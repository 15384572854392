import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Text from 'components/Text/Text.styles'
import {
  Checkbox,
  CheckboxPropTypes,
  ComponentWrapper,
  InfoIcon,
} from '@stokr/components-library'
import {
  Container,
  InfoBox,
  // OfferingPageLink,
  TermsOfOfferingLink,
} from './AgreementItem.styles'

const AgreementItem = ({
  title,
  text,
  checkbox,
  checkboxWithFileDownload,
  info,
  withTermsLink,
  withComplianceEmail,
  ventureName,
  isPrivateSale,
}) => (
  <Container>
    <Text>
      {title && <h3>{title}</h3>}
      {text && <p>{text}</p>}

      {/* {withTermsLink &&
        (!isPrivateSale || ventureName === 'blockstream-mining') && (
          <p>
            You can find all documents in the download section of the '
            <a
              href={'https://stokr.io/' + ventureName + '/terms'}
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: 'initial' }}
            >
              Terms
            </a>
            ' tab on the{' '}
            <span style={{ textTransform: 'uppercase' }}>{ventureName}</span>{' '}
            offering page.
          </p>
        )} */}
      {withComplianceEmail && (
        <p>
          It seems there is a problem with second ID verification document. You
          will be contacted by the compliance team. You can try again or contact
          the compliance team at{' '}
          <a href="mailto:compliance@stokr.io">compliance@stokr.io</a> in case
          of any questions.
        </p>
      )}
    </Text>
    {checkbox && (
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <Checkbox
          id={checkbox.id}
          name={checkbox.name || checkbox.id}
          text={
            <Fragment>
              {checkbox.text}
              {info && (
                <InfoIcon position="bottom" html={<InfoBox>{info}</InfoBox>} />
              )}
            </Fragment>
          }
          value={checkbox.value}
          checked={checkbox.checked}
          onChange={checkbox.onChange}
          onBlur={checkbox.onBlur}
        />
      </ComponentWrapper>
    )}
    {checkboxWithFileDownload && (
      <ComponentWrapper noPaddingHorizontal noPaddingBottom>
        <Checkbox
          id={checkboxWithFileDownload.id}
          name={checkboxWithFileDownload.name || checkboxWithFileDownload.id}
          disabled={checkboxWithFileDownload.disabled}
          text={
            <Fragment>
              {checkboxWithFileDownload.text}
              {info && (
                <InfoIcon position="bottom" html={<InfoBox>{info}</InfoBox>} />
              )}
            </Fragment>
          }
          value={checkboxWithFileDownload.value}
          checked={checkboxWithFileDownload.checked}
          onChange={checkboxWithFileDownload.onChange}
          onBlur={checkboxWithFileDownload.onBlur}
          onClick={checkboxWithFileDownload.onClick}
        />
      </ComponentWrapper>
    )}
  </Container>
)

AgreementItem.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  withTermsLink: PropTypes.bool,
  ventureName: PropTypes.string,
  info: PropTypes.string,
  checkbox: PropTypes.shape(CheckboxPropTypes),
  checkboxWithFileDownload: PropTypes.shape(CheckboxPropTypes),
}

AgreementItem.defaultProps = {
  title: '',
  info: '',
}

export default AgreementItem
