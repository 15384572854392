import React from 'react'
import PropTypes from 'prop-types'
import * as Yup from 'yup'

import { FormContainer, FormField, FormInfo, FormError } from './Form.styles'

export { FormField, FormInfo, FormError }

const Form = ({ children }) => (
  <FormContainer autoComplete="off" noValidate>
    {children}
  </FormContainer>
)

/**
 * @function validate
 * @description Generic function to perform field-level validation
 * @param {string} fieldName
 * @param {Object} formik
 *
 * @returns {(undefined|string)} returns error message if error exists, else return undefined
 */
export function validate(fieldName, formik) {
  const { values, validationSchema } = formik
  try {
    Yup.reach(validationSchema, fieldName).validateSync(values[fieldName], {
      abortEarly: false,
    })
    return undefined
  } catch (validateError) {
    return validateError.inner[0].errors[0]
  }
}

Form.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Form
