import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Reddit = props => (
  <Icon width="15" height="13" viewBox="0 0 15 13" {...props}>
    <defs>
      <path
        id="zg3oa"
        d="M1076.597 4482.766l-.726 3.33c1.574.076 3.015.464 4.311 1.323.471-.335.977-.521 1.569-.363.666.179 1.079.605 1.21 1.247.134.644-.081 1.205-.635 1.597-.207.146-.267.282-.262.528.023 1.304-.622 2.293-1.647 3.059-1.65 1.23-3.557 1.62-5.59 1.489-1.5-.098-2.91-.505-4.13-1.398-1.134-.83-1.845-1.883-1.772-3.33.005-.09-.086-.213-.17-.276-.795-.596-.987-1.521-.458-2.268.532-.752 1.473-.91 2.342-.407.088.051.262.052.349 0a8.025 8.025 0 0 1 3.907-1.169c.24-.008.335-.098.383-.337.23-1.124.489-2.242.732-3.364.065-.299.226-.39.538-.32.762.169 1.532.309 2.295.474.176.038.28.018.404-.126.405-.473.94-.576 1.467-.314.493.245.738.77.615 1.317-.101.45-.575.837-1.073.877-.547.044-1.04-.302-1.246-.853-.04-.108-.133-.253-.228-.274-.711-.16-1.43-.293-2.185-.442m-2.597 7.23c-.005-.54-.472-1.003-1.006-.996-.539.007-1 .477-.994 1.013.006.545.462.991 1.01.987.548-.004.994-.457.99-1.003m3.994 1.003a1.005 1.005 0 0 0 1.006-.99 1.025 1.025 0 0 0-.997-1.01c-.536-.004-1 .46-1.003 1-.002.546.446.997.994 1m-2.502 1.995c.145 0 .293.012.436-.002.695-.064 1.381-.164 1.935-.569.091-.066.17-.256.124-.33-.102-.162-.264-.082-.425-.001-.267.134-.543.278-.838.343-1.176.257-2.32.25-3.37-.382-.074-.044-.234.016-.354.028.014.095-.012.224.05.278.14.12.312.22.49.297.613.263 1.273.348 1.952.338"
      />
    </defs>
    <g>
      <g transform="translate(-1068 -4482)">
        <use fill="#fff" xlinkHref="#zg3oa" />
      </g>
    </g>
  </Icon>
)

export default Reddit
