import React from 'react'
import styled, { css } from 'styled-components'

const iconDirection = ({ direction }) => {
  switch (direction) {
    case 'right':
      return css`
        transform: rotate(-90deg);
      `
    case 'left':
      return css`
        transform: rotate(90deg);
      `
    case 'top':
      return css`
        transform: rotate(180deg);
      `
    case 'bottom':
    default:
      return css``
  }
}

const Icon = styled.svg`
  ${iconDirection};
`

export const ArrowSimple = props => (
  <Icon
    width="13"
    height="8"
    viewBox="0 0 13 8"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="STO-Pages" fill="none" fillRule="evenodd">
      <g
        id="1.-Desktop-Overview-Tab"
        transform="translate(-948 -1613)"
        fill="currentColor"
      >
        <g id="Risk-Considerations" transform="translate(512 1146)">
          <g id="Debt" transform="translate(1 450)">
            <polygon
              id="Shape-Copy-5"
              points="441.5 25 435 18.3043478 436.181818 17 441.5 22.4782609 446.818182 17 448 18.3043478"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default ArrowSimple
