import React, { useEffect, useState, useContext } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import styled from 'styled-components'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Button,
  Background,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  StokrLoader,
} from '@stokr/components-library'
// import { FAQ } from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import fixDecimals from 'utils/fix-decimals'
import { UserConsumer } from 'context/User/UserContext'
import { OrderContext } from 'context/Order/OrderContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { SectionTitlePageText } from './select-address'

const BoldText = styled.div`
  font-weight: 900;
  text-transform: uppercase;
  display: initial;
`

const PendingPaymentPage = () => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  let { order } = useContext(OrderContext)
  let { project } = useContext(ProjectContext)
  let { user } = useContext(UserContext)
  const navigator = useCustomNav()

  useEffect(() => {
    if (user.selectedAddress) {
      setShouldRenderPage(true)
    } else {
      navigator.navigate(`/${project.name}`)
    }
  }, [])

  // if (!user.selectedAddress) {
  //   Navigate(`/${project.name}`)
  //   return <></>
  // }

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 2,
        activeIndex: 3,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <UserConsumer>
        {({ user }) => (
          <Row main>
            <Column part={8}>
              <Background src={backgroundImage} mobileRatio="16:9" />
            </Column>

            <Column part={8} withBorder>
              <ComponentWrapper>
                <SectionTitle>{SectionTitlePageText(project)}</SectionTitle>
              </ComponentWrapper>

              <ComponentWrapper noPaddingTop>
                <Text>
                  <h3>YOU HAVE INVESTED WITH {user.currencyName}</h3>

                  <p>
                    Once we receive your investment you will receive the
                    securities at the end of the offering period. Hold tight!
                  </p>
                  <p>
                    For any queries, don’t hesitate to reach us at{' '}
                    <a
                      href="mailto:support@stokr.io"
                      style={{ color: 'inherit' }}
                    >
                      support@stokr.io
                    </a>
                    !
                  </p>
                  {/* 
                  <p>
                    Please send the{' '}
                    <BoldText>
                      {user.currencySymbol}{' '}
                      {`${fixDecimals(
                        order.currencyValue,
                        project.euroDecimals,
                      )}`}
                    </BoldText>{' '}
                    to:{' '}
                  </p>
                  <p>
                    <BoldText>Account holder</BoldText>:{' '}
                    {project.company.accountHolder}
                  </p>
                  <p>
                    <BoldText>IBAN</BoldText>: {project.company.iban}
                  </p>
                  <p>
                    <BoldText>BIC</BoldText>: {project.company.bic}
                  </p>
                  <p>
                    <BoldText>Reference</BoldText>: STOKR-{user._id}
                  </p> */}
                </Text>
              </ComponentWrapper>

              <ComponentWrapper
                noPaddingTop
                style={{ marginBottom: '5rem', marginTop: '5rem' }}
              >
                <Button
                  minWidth="240px"
                  onClick={() =>
                    window.location.replace(
                      `https://dashboard.${process.env.REACT_APP_WEBSITE_DOMAIN}`,
                    )
                  }
                >
                  My dashboard
                </Button>
              </ComponentWrapper>
            </Column>
          </Row>
        )}
      </UserConsumer>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default PendingPaymentPage
