import React from 'react'
import styled, { css } from 'styled-components'

const iconDirection = ({ direction }) => {
  switch (direction) {
    case 'right':
      return css`
        transform: rotate(90deg) scale(-1, 1);
      `
    case 'left':
      return css`
        transform: rotate(-90deg);
      `
    case 'bottom':
      return css`
        transform: rotate(180deg);
      `
    case 'top':
    default:
      return css``
  }
}

const Icon = styled.svg`
  ${iconDirection};
`

export const Arrow = props => (
  <Icon
    width="18"
    height="10"
    viewBox="0 0 18 10"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      id="STO-Pages"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <g
        id="2.-Tablet-Pitch-Tab"
        transform="translate(-120 -5387)"
        stroke="#FFF"
        strokeWidth="1.5"
      >
        <g id="Wrap-Up" transform="translate(0 5299)">
          <g id="Top" transform="translate(64 84)">
            <g id="Top-Icon" transform="translate(50 5)">
              <path
                d="M9.917,5.583 L7.25,8.25"
                id="Stroke-1"
                transform="matrix(0 -1 -1 0 15.5 15.5)"
              />
              <polyline
                id="Stroke-3"
                transform="scale(1 -1) rotate(90 21.834 0)"
                points="13.5835 -1.4165 21.5835 6.5835 18.2505 9.9165"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default Arrow
