export const ProjectTypes = {
  FUND: 'fund',
  COMPANY: 'company',
  TRANCHES: 'tranches',
}
export const ShareTypes = {
  PROFIT: 'Profit share',
  REVENUE: 'Revenue share',
  TARGET_RETURN: 'Target return',
}

export const ProfessionalInvestorTypes = {
  PROFESSIONAL: 'professional',
  NOT_PROFESSIONAL: 'not professional',
  NOT_CLASSIFIED: 'not classified',
}

export const USInvestorAcreditationStatuses = {
  US_ACCREDITED: 'US accredited',
  NOT_US_ACCREDITED: 'not US accredited',
  NOT_CLASIFIED: 'not classified',
}
