import { css } from 'styled-components/macro'

const semanticUiStyle = css`
  .ui.dimmer {
    background-color: rgba(0, 0, 0, 0.7);
  }

  .ui.modals.dimmer {
    align-items: center;
    padding: 0;
  }

  .ui.modal {
    .ui.grid {
      margin: 0;

      & > .row {
        padding: 0;
      }

      & > .row > .column {
        padding: 0 !important;
      }
    }
  }

  .ui.popup {
    border-radius: 10px;
    font-size: 11px;
    line-height: 14px;
    letter-spacing: 0.4px;
    padding: 12px;
    box-shadow: 0 0 12px 8px rgba(0, 0, 0, 0.03),
      0 0 6px 3px rgba(0, 0, 0, 0.02);
    border: none;

    &::before {
      background: none !important;
      border: 7px solid transparent !important;
      box-shadow: none !important;
      margin: 0 !important;
    }

    &.top::before {
      border-top-color: ${props => props.theme.cPrimary}!important;
      transform: rotate(0) translateY(100%) translateX(-50%) !important;
      left: 50% !important;
      bottom: 0 !important;
    }

    &.bottom::before {
      border-bottom-color: ${props => props.theme.cPrimary}!important;
      transform: rotate(0) translateY(-100%) translateX(-50%) !important;
      left: 50% !important;
      top: 0 !important;
    }

    &.left::before {
      border-left-color: ${props => props.theme.cPrimary}!important;
      transform: rotate(0) translateX(100%) !important;
      right: 0 !important;
      top: auto !important;
    }

    &.right::before {
      border-right-color: ${props => props.theme.cPrimary}!important;
      transform: rotate(0) translateX(-100%) !important;
      left: 0 !important;
      top: auto !important;
    }

    &.top.left::before {
      border: 7px solid transparent !important;
      border-top-color: ${props => props.theme.cPrimary}!important;
      left: 0 !important;
      transform: rotate(0) translateY(100%) translateX(0) !important;
    }
  }
`

export default semanticUiStyle
