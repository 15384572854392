import React, { useEffect, useState, useContext } from 'react'
import { useCustomNav } from 'utils/customNavigate'
import Layout from 'components/Layout/Layout'
import SEO from 'components/SEO/SEO'
import Text from 'components/Text/Text.styles'
import {
  Background,
  Button,
  Row,
  Column,
  ComponentWrapper,
  SectionTitle,
  StokrLoader,
  AuthContext,
} from '@stokr/components-library'
// import { FAQ } from '@stokr/components-library'
import backgroundImage from 'static/images/covers/rocket.png'
import { ProjectContext } from 'context/Project/ProjectContext'
import { UserContext } from 'context/User/UserContext'
import { useLocation } from 'react-router-dom'
import { SectionTitlePageText } from './select-address'

const InvestingOopsPage = ({}) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)
  let { project } = useContext(ProjectContext)
  let { user } = useContext(UserContext)
  let { logoutUser } = useContext(AuthContext)
  const navigator = useCustomNav()
  const location = useLocation()

  useEffect(() => {
    if (user && !user.selectedAddress) {
      navigator.navigate(`/${project.name}`)
    } else {
      setShouldRenderPage(true)
    }
  }, [])

  return shouldRenderPage ? (
    <Layout
      progress={{
        items: [
          { id: 'select-address', handleClick: () => {} },
          { id: 'order-tokens', handleClick: () => {} },
          { id: 'confirm', handleClick: () => {} },
        ],
        doneIndex: 2,
        activeIndex: 3,
      }}
      footerColor="red"
    >
      <SEO
        title={`Invest in ${project.name}`}
        keywords={['gatsby', 'application', 'react']}
      />
      <Row main>
        <Column part={8}>
          <Background src={backgroundImage} mobileRatio="16:9" />
        </Column>

        <Column part={8} withBorder>
          <ComponentWrapper>
            <SectionTitle>{SectionTitlePageText(project)}</SectionTitle>
          </ComponentWrapper>
          <ComponentWrapper noPaddingTop>
            <Text>
              <h3>{location.state.title || 'OOPS...'}</h3>
              {
                <p>
                  {location.state.message || (
                    <>
                      Something went wrong. Please contact{' '}
                      <a
                        href="mailto:support@stokr.io"
                        style={{ color: 'inherit', fontWeight: 300 }}
                      >
                        support@stokr.io
                      </a>{' '}
                      .
                    </>
                  )}
                </p>
              }
            </Text>
          </ComponentWrapper>
          {user ? (
            <ComponentWrapper noPaddingTop>
              <Button
                fluid
                onClick={() => {
                  if (location.state.tokenExpired) {
                    logoutUser()
                  }
                  navigator.navigate(
                    location.state.ctaUrl || `/${project.name}`,
                  )
                }}
              >
                {location.state.ctaLabel || 'BACK TO BEGINNING'}
              </Button>
            </ComponentWrapper>
          ) : (
            <ComponentWrapper noPaddingTop>
              <Button
                fluid
                onClick={() =>
                  window.location.replace(
                    location.state.ctaUrl || 'https://dashboard.stokr.io/',
                  )
                }
              >
                {location.state.ctaLabel || 'BACK TO DASHBOARD'}
              </Button>
            </ComponentWrapper>
          )}
        </Column>
      </Row>
    </Layout>
  ) : (
    <StokrLoader />
  )
}

export default InvestingOopsPage
