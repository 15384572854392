import axios from 'axios'
import Cookies from 'js-cookie'
import { BASE_URL } from 'utils/base-api-urls'

class User {
  static getAccessToken() {
    return Cookies.get('STOKR_ACCESS_TOKEN')
  }

  static login = (url, data) =>
    new Promise(async (resolve, reject) => {
      try {
        const result = await axios.post(`auth/${url}`, data)

        if (url === 'login') {
          const accessToken = result.data.access_token
          const inOneHour = 1 / 24

          const cookieOptions = {
            expires: inOneHour,
          }

          if (process.env.NODE_ENV === 'production') {
            cookieOptions.domain = '.stokr.io'
          }

          Cookies.set('STOKR_ACCESS_TOKEN', accessToken, cookieOptions)

          const userType = result.data.data.user.user_type
          const country = result.data.data.user.country

          if (userType === 'investor' && !country) {
            window.location.href = 'https://signup.stokr.io/welcome'
          } else if (userType !== 'investor') {
            window.location.href = 'https://admin.stokr.io'
          }
        }

        resolve(result)
      } catch (error) {
        reject(error)
      }
    })

  static logout() {
    const cookieDomain = {}

    if (process.env.NODE_ENV === 'production') {
      cookieDomain.domain = '.stokr.io'
    }

    Cookies.remove('STOKR_ACCESS_TOKEN', cookieDomain)
  }

  static getUser() {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/user/get`)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static updateUser(data) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/user/updateProfile`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }

  static async getTokenBalance() {
    return (
      await axios.create({ baseURL: BASE_URL }).post('/wallets/tokenBalances')
    ).data
  }

  static allowedForPrivateSale(data) {
    // return (await axios.post(`${BASE_URL}/private-investor/check`, data)).data
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/private-investor/check`, data)
        .then((response) => {
          resolve(response.data)
        })
        .catch((err) => {
          reject(err)
        })
    })
  }
}
export default User
