export const COLUMN = 64
export const BASE_FONT_SIZE = 16
export const BASE_MARGIN = 16
export const BASE_MARGIN_PX = `${BASE_MARGIN}px`
export const BASE_MARGIN_2X_PX = `${BASE_MARGIN * 2}px`
export const MAX_WIDTH = 1920 - BASE_MARGIN * 2

export const DESKTOP_MEDIA = 1024
export const TABLET_MEDIA = 769
export const PHONE_MEDIA = 320

export const BASE_COLOR_HEX = '#202020'
export const BLUE_BASE_HEX = '#0050ca'
export const BLUE_BASE_RELEASED_HEX = '#004bb7'
export const GRAY_BASE_HEX = '#ededed'
export const GRAY_SECONDARY_HEX = '#9b9b9b'
export const GRAY_DEEP_HEX = '#979797'
export const GREEN = '#84FB6F'
export const WHITE_HEX = '#fff'
export const RED_BASE_HEX = '#ee220d'
export const RED_BASE_RELEASE_HEX = '#D2200D'

export const SLIDER_HEIGHT = '700px'
export const SLIDER_TABLET_HEIGHT = '500px'
export const SLIDER_MOBILE_HEIGHT = '300px'

export const CAPITAL_ANIMATION_LENGTH = 1.5
