import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Check = props => (
  <Icon
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="14"
    viewBox="0 0 16 14"
    {...props}
  >
    <defs>
      <path id="5fdua" d="M1081.11 3455.04v-2.75h7.9v2.75z" />
      <path id="5fdub" d="M1083.56 3452.57v-3.61h15.06v3.61z" />
    </defs>
    <g>
      <g transform="translate(-1081 -3444)">
        <g transform="rotate(-315 1084.5 3454)">
          <use fill="#fff" xlinkHref="#5fdua" />
        </g>
        <g transform="rotate(-225 1091 3451)">
          <use fill="#fff" xlinkHref="#5fdub" />
        </g>
      </g>
    </g>
  </Icon>
)

export default Check
