import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Medium = props => (
  <Icon
    width="12"
    height="9"
    viewBox="0 0 12 9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Symbols" fill="none" fillRule="evenodd">
      <g id="Footer" transform="translate(-834 -287)" fill="currentColor">
        <g id="Group" transform="translate(704 275)">
          <g id="Group-2" transform="translate(130 12)">
            <path
              d="M1.33026175,1.71792233 C1.34428419,1.58754232 1.29146635,1.45893018 1.18863515,1.37142089 L0.141626603,0.178112348 L0.141626603,0 L3.3938969,0 L5.90765224,5.21299044 L8.11758814,1.96272387e-16 L11.2179487,0 L11.2179487,0.178112348 L10.3223825,0.99000412 C10.2452591,1.04569185 10.2069311,1.13717884 10.2228232,1.2277819 L10.2228232,7.19344065 C10.2069311,7.28360174 10.2452591,7.37508873 10.3223825,7.43077646 L11.1969151,8.24266823 L11.1969151,8.42078058 L6.79760951,8.42078058 L6.79760951,8.24266823 L7.70392628,7.41088798 C7.79273504,7.32691442 7.79273504,7.30216432 7.79273504,7.17355217 L7.79273504,2.35170175 L5.27383814,8.40133407 L4.93356036,8.40133407 L2.00053419,2.35170175 L2.00053419,6.40629898 C1.97622863,6.57645594 2.03605769,6.74838076 2.16272703,6.87124735 L3.34107906,8.22277976 L3.34107906,8.40133407 L0,8.40133407 L0,8.22277976 L1.17835203,6.87124735 C1.30455395,6.7479388 1.3606437,6.57513004 1.33026175,6.40629898 L1.33026175,1.71792233 Z"
              id="Shape"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default Medium
