import { css } from 'styled-components/macro'

import OpenSansRegularWOFF2 from 'static/fonts/OpenSans/OpenSans-Regular.woff2'
import OpenSansRegularWOFF from 'static/fonts/OpenSans/OpenSans-Regular.woff'
import OpenSansRegularTTF from 'static/fonts/OpenSans/OpenSans-Regular.ttf'

import OpenSansSemiBoldWOFF2 from 'static/fonts/OpenSans/OpenSans-SemiBold.woff2'
import OpenSansSemiBoldWOFF from 'static/fonts/OpenSans/OpenSans-SemiBold.woff'
import OpenSansSemiBoldTTF from 'static/fonts/OpenSans/OpenSans-SemiBold.ttf'

import OpenSansBoldWOFF2 from 'static/fonts/OpenSans/OpenSans-Bold.woff2'
import OpenSansBoldWOFF from 'static/fonts/OpenSans/OpenSans-Bold.woff'
import OpenSansBoldTTF from 'static/fonts/OpenSans/OpenSans-Bold.ttf'

import OpenSansLightWOFF2 from 'static/fonts/OpenSans/OpenSans-Light.woff2'
import OpenSansLightWOFF from 'static/fonts/OpenSans/OpenSans-Light.woff'
import OpenSansLightTTF from 'static/fonts/OpenSans/OpenSans-Light.ttf'

import OpenSansExtraBoldWOFF2 from 'static/fonts/OpenSans/OpenSans-ExtraBold.woff2'
import OpenSansExtraBoldWOFF from 'static/fonts/OpenSans/OpenSans-ExtraBold.woff'
import OpenSansExtraBoldTTF from 'static/fonts/OpenSans/OpenSans-ExtraBold.ttf'

import IoniconsWOFF2 from 'static/fonts/Ionicons/Ionicons.woff2'
import IoniconsWOFF from 'static/fonts/Ionicons/Ionicons.woff'
import IoniconsTTF from 'static/fonts/Ionicons/Ionicons.ttf'

import IcomoonEOT from 'static/fonts/icomoon/icomoon.eot'
import IcomoonWOFF from 'static/fonts/icomoon/icomoon.woff'
import IcomoonTTF from 'static/fonts/icomoon/icomoon.ttf'
import IcomoonSVG from 'static/fonts/icomoon/icomoon.svg'

import 'static/fonts/Ionicons/ionicons.min.css'

const fontFaces = css`

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansRegularWOFF2}) format('woff2'),
        url(${OpenSansRegularWOFF}) format('woff'),
        url(${OpenSansRegularTTF}) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansSemiBoldWOFF2}) format('woff2'),
        url(${OpenSansSemiBoldWOFF}) format('woff'),
        url(${OpenSansSemiBoldTTF}) format('truetype');
    font-weight: 600;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansBoldWOFF2}) format('woff2'),
        url(${OpenSansBoldWOFF}) format('woff'),
        url(${OpenSansBoldTTF}) format('truetype');
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansLightWOFF2}) format('woff2'),
        url(${OpenSansLightWOFF}) format('woff'),
        url(${OpenSansLightTTF}) format('truetype');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'Open Sans';
    src: url(${OpenSansExtraBoldWOFF2}) format('woff2'),
        url(${OpenSansExtraBoldWOFF}) format('woff'),
        url(${OpenSansExtraBoldTTF}) format('truetype');
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family:"Ionicons";
    src: url(${IoniconsWOFF2}) format("woff2"),
         url(${IoniconsWOFF}) format("woff"),
         url(${IoniconsTTF}) format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'icomoon';
    src:  url('${IcomoonEOT}');
    src:  url('${IcomoonEOT}#iefix') format('embedded-opentype'),
      url('${IcomoonTTF}') format('truetype'),
      url('${IcomoonWOFF}') format('woff'),
      url('${IcomoonSVG}') format('svg');
    font-weight: normal;
    font-style: normal;
  }
`

export default fontFaces
