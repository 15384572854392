import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StokrLoader } from '@stokr/components-library'

const BtcPayCheckout = ({ invoiceId, onEnter, onLeave }) => {
  const [shouldRenderPage, setShouldRenderPage] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      window.btcpay.showInvoice(invoiceId)
      window.btcpay.onModalWillEnter(onEnter)
      window.btcpay.onModalWillLeave(onLeave)
    }, 0)
    setShouldRenderPage(true)
  }, [])

  return shouldRenderPage ? '' : <StokrLoader />
}

BtcPayCheckout.propTypes = {
  invoiceId: PropTypes.string.isRequired,
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
}

export default BtcPayCheckout
