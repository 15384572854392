import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Info = props => (
  <Icon
    width="16"
    height="16"
    viewBox="0 0 16 16"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="STO-Pages" fill="none" fillRule="evenodd">
      <g id="2.-Desktop-Pitch-Tab" transform="translate(-957 -180)">
        <g id="Hero" transform="translate(0 94)">
          <g id="Tooltip" transform="translate(957 86)">
            <path
              d="M7.70634725,7.11558038e-07 C4.59053555,-0.00134762449 1.78079233,1.91363693 0.587507493,4.85185624 C-0.605777342,7.79007555 0.0524401862,11.172782 2.25518484,13.4223708 C4.45792949,15.6719596 7.77132172,16.3453242 10.6500831,15.1284251 C13.5288444,13.9115261 15.4059388,11.0440588 15.4059391,7.86333404 C15.4023412,3.52335835 11.9577501,0.00551374093 7.70634725,7.11558038e-07 Z"
              id="Shape"
              fill="#202020"
              fillRule="nonzero"
              opacity="0.2"
            />
            <ellipse
              id="Oval"
              fill="#FFF"
              cx="7.757"
              cy="5.117"
              rx="1"
              ry="1"
            />
            <polygon
              id="Rectangle"
              fill="#FFF"
              points="7.10639456 7.16 8.40206803 7.16 8.40206803 9.31911253 8.40206803 11.5146667 7.10639456 11.5146667 7.10639456 9.8880281"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default Info
