import axios from 'axios'
import { BASE_URL } from 'utils/base-api-urls'

class Order {
  static async getGasPrice() {
    return (await axios.post(`${BASE_URL}/investment/gasprice`)).data
  }
  static async storeInvestment(data) {
    return (await axios.post(`${BASE_URL}/investment/create`, data)).data
  }

  static async getInvestment() {
    return (await axios.post(`${BASE_URL}/investment/get`)).data
  }

  static async createInvoiceBtc(data) {
    return (await axios.post(`${BASE_URL}/btcpay/create-invoice`, data)).data
  }

  static async createInvoiceBitfinex(data) {
    return (await axios.post(`${BASE_URL}/bitfinex/create-invoice`, data)).data
  }

  static async getConcatinatedFile(data) {
    return await axios.post(`${BASE_URL}/documents/getConcatenated`, data, {
      responseType: 'blob',
    })
  }
}
export default Order
