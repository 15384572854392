import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Order from './Order'
// import ExchangeRates from 'context/ExchangeRates/ExchangeRates'

import * as urls from 'constants/paths'
import { withRouter } from 'utils/customNavigate'

export const OrderContext = React.createContext()

const sum = (xs) => xs.reduce((s, x) => s + x, 0)

class OrderProviderClass extends Component {
  state = {
    order: undefined,
    isFetchingOrder: true,
  }

  componentDidUpdate() {
    const { exchangeRates, project, user } = this.props
    const { hasStartedSetOrder } = this.state

    if (exchangeRates && project && user && !hasStartedSetOrder) {
      if (
        !(
          project.isOpen ||
          user.testingUser ||
          (project.privateSaleOpen && user.isPrivateSale)
        )
      ) {
        this.props.navigate(urls.SALE_CLOSED)
      }
      this.setState({ hasStartedSetOrder: true })
    }
  }

  getGasPrice = async () => {
    let { order } = this.state
    const gasPrice = (await Order.getGasPrice()).price * 1e9
    this.setState({
      order: {
        ...order,
        gasPrice,
      },
    })
  }

  initializeOrder = () => {
    const { project, user } = this.props
    const currentSale =
      !user.isPrivateSale || project.type === 'tranches'
        ? (project.sales || []).find((sale) => sale.isVariable)
        : (project.sales || []).find((sale) => sale.name === 'private')
    if (!currentSale) {
      this.props.navigate(urls.OOPS, {
        state: {
          message: 'No Sale found for this project. Please contact support.',
        },
      })
    }

    const order = {
      ...currentSale,
      currencyValue: 0,
      tokenAmount: 0,
    }
    if (project.type === 'tranches' || !user.isPrivateSale) {
      const soldInOldSales = sum(
        (project.sales || []).map((sale) => Number(sale.soldinCy) || 0),
      )
      order.tokenAvailable =
        (Number(currentSale.hardcap) +
          Number(soldInOldSales) -
          Number(project.totalSold)) /
        Number(currentSale.tokenPrice)
    } else {
      const privateSales =
        project.sales?.filter(({ isVariable }) => !isVariable) || []
      const totalPrivateHardCap = sum(privateSales.map((sale) => sale.hardcap))
      order.tokenAvailable =
        (Number(totalPrivateHardCap) - Number(project.totalPrivateSold)) /
        Number(order.tokenPrice)
    }

    this.setState({
      order,
      isFetchingOrder: false,
    })
  }

  updateOrder = (data) => {
    let { order } = this.state
    this.setState({
      order: {
        ...order,
        ...data,
      },
    })
  }

  storeInvestment = async (data) => {
    const { order } = this.state

    data.sale = order.name

    const investmentObj = await Order.storeInvestment(data)
    this.setState({
      order: { ...order, investmentId: investmentObj.investment._id },
    })

    return investmentObj.investment
  }

  storeTxSignature = (r, s, v) => {
    let { order } = this.state
    this.setState({
      order: {
        ...order,
        txData: {
          ...order.txData,
          r: `0x${r}`,
          s: `0x${s}`,
          v: `0x${v}`,
        },
      },
    })
  }

  handleFileDownload = async (data) => {
    try {
      const result = await Order.getConcatinatedFile(data)
      const blobData = new Blob([result.data])

      return blobData
    } catch (error) {
      console.log('🚀 ~ error', error)
    }
  }

  render() {
    const { children } = this.props

    return (
      <OrderContext.Provider
        value={{
          ...this.state,
          initializeOrder: this.initializeOrder,
          updateOrder: this.updateOrder,
          storeInvestment: this.storeInvestment,
          storeTxSignature: this.storeTxSignature,
          createInvoiceBtc: Order.createInvoiceBtc,
          createInvoiceBitfinex: Order.createInvoiceBitfinex,
          handleFileDownload: this.handleFileDownload,

          getInvestment: Order.getInvestment,
        }}
      >
        {children}
      </OrderContext.Provider>
    )
  }
}

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}
const capitalizeProjectName = (projectName) => {
  const splitName = projectName.split('-')
  return splitName.map((name) => capitalizeFirstLetter(name)).join('-')
}

OrderProviderClass.propTypes = {
  children: PropTypes.node.isRequired,
}

export const OrderProvider = withRouter(OrderProviderClass)
export const OrderConsumer = OrderContext.Consumer
