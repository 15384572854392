import { createBreakpoints } from 'styled-breakpoints'

export const config = {
  mobile: '450px',
  tablet: '768px',
  desktop: '992px',
  lgDesktop: '1020px',
  highDesktop: '1920px',
}

export const breakpoint = createBreakpoints(config)

export default breakpoint
