import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Telegram = props => (
  <Icon
    width="12"
    height="9"
    viewBox="0 0 12 9"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="Symbols" fill="none" fillRule="evenodd">
      <g id="Footer" transform="translate(-794 -286)" fill="currentColor">
        <g id="Group" transform="translate(704 275)">
          <g id="Group-3" transform="translate(90 11)">
            <path
              d="M8.5929391,9.49400205 C8.74344315,9.60344218 8.93741577,9.63080221 9.11035521,9.56360213 C9.28329465,9.49592205 9.41042851,9.34424188 9.44875563,9.16040167 C9.85492961,7.20007941 10.840217,2.23831371 11.2099335,0.455111664 C11.2379777,0.32071151 11.1912373,0.181031349 11.0884085,0.0912712461 C10.9855796,0.00151114295 10.8430214,-0.0244088868 10.7158876,0.0240711689 C8.75606306,0.769032025 2.72047661,3.0946347 0.253518868,4.03207577 C0.0969385639,4.09159584 -0.00495548443,4.24615602 0.000185958374,4.41559621 C0.00579480507,4.58551641 0.117036931,4.73287658 0.277356466,4.78231664 C1.38370148,5.12215703 2.83592537,5.59495757 2.83592537,5.59495757 C2.83592537,5.59495757 3.51459582,7.69975999 3.86842056,8.77016122 C3.91282393,8.90456137 4.01518539,9.01016149 4.15026511,9.04664154 C4.28487743,9.08264158 4.42883783,9.04472153 4.52932967,8.94728142 C5.0976928,8.39624079 5.97641212,7.54423981 5.97641212,7.54423981 C5.97641212,7.54423981 7.64597882,8.80136125 8.5929391,9.49400205 Z M3.44682225,5.32903726 L4.23159339,7.98728032 L4.40593504,6.30391838 C4.40593504,6.30391838 7.43798408,3.49543516 9.16644367,1.89463332 C9.2169233,1.84759326 9.22393435,1.76887317 9.181868,1.71367311 C9.14026906,1.65847305 9.06361482,1.64551303 9.00612414,1.68295307 C7.00283106,2.99671458 3.44682225,5.32903726 3.44682225,5.32903726 Z"
              id="telegram-1"
            />
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default Telegram
