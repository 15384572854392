const colors = {
  black: '#202020',
  grey: '#f0f0f0',
  lightGrey: '#e1e1e1',
  orangishRed: '#ee220d',
  white: '#ffffff',
  lightPeach: '#fbd2d1',
  salmon: '#f57c76',
  paleSalmon: '#f8a7a4',
  pastelRed: '#f24f45',
  reddishBrown: '#8e150a',
  reddyBrown: '#5e0f08',
  rustyRed: '#be1b09',
  lightPeriwinkle: '#ceddf5',
  carolinaBlue: '#9cb9eb',
  lighterGreen: '#84fb6f',
  blue: '#0050ca',
  blueDark: '#004BB7',
  softBlue: '#6a97e0',
  darkBrown: '#300606',
  azul: '#3873d5',
  darkishBlue: '#0240a2',
  marineBlue: '#032f78',
  navy: '#021f51',
  navy2: '#021128',
  offWhite: '#e6fee2',
  veryLightGreen: '#cefec6',
  lightPastelGreen: '#b5fca9',
  easterGreen: '#9bfc8c',
  freshGreen: '#69c858',
  fernGreen: '#4d9741',
  pine: '#34652c',
  pine2: '#1a3315',
  black70: 'rgba(32, 32, 32, 0.7)',
  yellow: '#f8e71c',
  yellowLight: '#fffedf',
  progressGreen: '#79ca65',
  progressAccentStart: '#69BD53',
  progressAccentStop: '#A9EF9C',
  progressRedStart: '#EE220D',
  progressRedStop: '#F57C76',
  progressBlueStart: '#0240A2',
  progressBlueStop: '#66A3FE',
  grey2: '#9b9b9b',
  grey3: '#f8f8f8',
  midRed: '#e3210d',
}

export default colors
