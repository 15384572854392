import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import {
  Button,
  ComponentScroll,
  ComponentWrapper,
  CryptoAddressWrapper,
  SectionTitle,
  CryptoAddress,
  AgreementItem,
} from '@stokr/components-library'
import { ModalWrapper } from 'components/Modal/Modal.styles'
import { SignSubText } from './_styles'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { OrderContext } from 'context/Order/OrderContext'
import fixDecimals from 'utils/fix-decimals'
import TransactionInfo from 'components/TransactionInfo/TransactionInfo'
import parse from 'html-react-parser'

import { useCustomNav } from 'utils/customNavigate'
import * as urls from 'constants/paths'
import { CheckboxContext } from 'context/Checkbox/CheckboxContext'
import { SectionTitlePageText } from 'pages/select-address'

const SignSubConnectBitfinex = (props) => {
  const { project } = useContext(ProjectContext)
  const { getCheckbox, setCheckedCheckbox, checkedCheckboxes } =
    useContext(CheckboxContext)
  const navigator = useCustomNav()

  const { user } = useContext(UserContext)
  const { order, storeInvestment } = useContext(OrderContext)
  const [shareData, setShareData] = useState(false)
  const [isLoadingTransaction, setIsLoadingTranscation] = useState(false)
  const [newTabOpened, setNewTabOpened] = useState(false)
  const [currencyValue, setcurrencyValue] = useState(0)
  const [dataSharingCheckbox, setdataSharingCheckbox] = useState()
  const [shouldRenderPage, setshouldRenderPage] = useState(false)

  const [errorMsgPopup, seterrorMsgPopup] = useState()

  React.useEffect(() => {
    fetchCheckbox()
  }, [])

  const fetchCheckbox = async () => {
    try {
      const result = await getCheckbox({ labels: ['shareDataBitfinex'] })
      if (result?.shareDataBitfinex) {
        setdataSharingCheckbox(result.shareDataBitfinex)
        setshouldRenderPage(true)
      }
    } catch (error) {
      console.log('🚀 ~ file: SignSubConnectBitfinex.js:50 ~ error:', error)
    }
  }

  const handleChange = async (e) => {
    setShareData(!shareData)
  }

  const onSubmit = async () => {
    let investment

    setIsLoadingTranscation(true)

    try {
      const data = {
        project: project._id,
        isPrivateSale: user.isPrivateSale,
        tokenAmount: order.tokenAmount,
        tokenPrice: order.tokenPrice,
        currencyType: user.currencyInternalReference,
        currencyAmount: order.currencyValue,
        tokenName: project.tokenName,
        tokenSymbol: project.tokenSymbol,
        investorTxStatus: 'Started',
        checkedCheckboxes,
      }

      if (order.feeInEURorUSD) {
        if (order.feePercentage) data.feePercentage = order.feePercentage
        data.feeInEURorUSD = order.feeInEURorUSD
      }
      if (user.secondaryCurrency) {
        data.secondaryCurrency = user.secondaryCurrency
        data.secondaryCurrencyinEURorUSD = user.secondaryCurrencyinEURorUSD
      }
      if (project.isLiquid) {
        investment = await storeInvestment({
          ...data,
          GAID: user.selectedAddress.address,
          tokenUID: project.secondaryAssetId,
        })
      } else {
        throw new Error('Invalid project token issuance type')
      }
    } catch (error) {
      console.error(error)
      navigator.navigate(urls.OOPS, {
        state:
          error?.response?.status === 401 || error?.response?.status === 403
            ? {
                message:
                  'We are sorry, your session expired, Please log in again.',
                tokenExpired: true,
                ctaLabel: 'GO TO LOGIN',
              }
            : {},
      })
      return
    }

    if (!investment.bitfinexInvoiceId) {
      navigator.navigate(urls.OOPS, {
        state: {
          message:
            'We are sorry. There is an error connecting with Bitfinex Pay, so please contact support@stokr.io.',
        },
      })
      return
    }

    const popup = window.open(
      `https://pay.bitfinex.com/gateway/order/${investment.bitfinexInvoiceId}`,
      '_blank',
    )

    if (!popup || popup.closed || typeof popup.closed === 'undefined') {
      // Popup was blocked
      seterrorMsgPopup(true)
    } else {
      setNewTabOpened(true)
    }

    props.changeStep()
  }

  React.useEffect(() => {
    if (user.secondaryCurrency) {
      let priceinEUR = order.currencyValue * user.selectedCurrencyinEURorUSD
      let priceinBTC = priceinEUR / user.secondaryCurrencyinEURorUSD
      setcurrencyValue(priceinBTC)
    } else {
      setcurrencyValue(order.currencyValue)
    }
  }, [user, order])

  return (
    <ModalWrapper>
      {shouldRenderPage ? (
        <>
          <ComponentWrapper noPaddingBottom>
            <SectionTitle>{SectionTitlePageText(project)}</SectionTitle>
          </ComponentWrapper>

          <ComponentScroll fullHeight>
            <ComponentWrapper noPaddingTop>
              <SignSubText>
                <h3>Review</h3>
                {newTabOpened ? (
                  <p>
                    A new tab should open and direct you to Bitfinex Pay. You
                    can keep this window open for reference and close it as soon
                    as you have done the payment.
                  </p>
                ) : (
                  <p>Please review your transaction details below.</p>
                )}
              </SignSubText>
            </ComponentWrapper>

            <ComponentWrapper>
              <TransactionInfo
                value1={fixDecimals(currencyValue, user.currencyDecimals)}
                unit1={user.secondaryCurrency || user.currencySymbol}
                address1={user.selectedAddress.address}
                value2={fixDecimals(
                  order.tokenAmount,
                  project.tokenRoundingDecimals,
                )}
                unit2={project.tokenSymbol}
                owner1={user.isBFXPay ? '(ESTIMATED)' : ''}
                owner2=""
                address2={user.selectedAddress.address}
                leftCaption={'+Transfer fees'}
                red
                redGreen
              />
              {/* {user.currencyInternalReference === 'tether' && (
            <TransactionDetails
              toggleOpen="view recommended gas price"
              //filled
              smallPadding
              data={[
                {
                  label: 'recommended gas price',
                  value: `${order.gasPrice / 1e9} GWEI (${
                    order.gasPrice / 1e18
                  } ETH)`,
                  valueDescription: '(on top of investment)',
                },
              ]}
              style={{ marginTop: 20 }}
            />
          )} */}
            </ComponentWrapper>
            {!newTabOpened ? (
              <>
                <ComponentWrapper>
                  {dataSharingCheckbox && (
                    <AgreementItem
                      text={parse(dataSharingCheckbox.text, {
                        replace: (domNode) => {
                          //change <u> tag to <TermsOfOfferingLink> tag and add onClick event
                          if (domNode.type === 'tag' && domNode.name === 'a') {
                            return (
                              <a
                                href="https://stokr.io/privacy"
                                target="_blank"
                                style={{ color: 'initial' }}
                                rel="noreferrer"
                              >
                                {domNode.children[0]?.data}
                              </a>
                            )
                          }
                        },
                      })}
                      checkbox={{
                        id: dataSharingCheckbox.label,
                        name: dataSharingCheckbox.label,
                        text: parse(dataSharingCheckbox.agreementText),
                        onChange: (e) => {
                          handleChange(e)
                          setCheckedCheckbox(e)
                        },
                        //onBlur: handleBlur,
                        checked: shareData,
                      }}
                    />
                  )}
                </ComponentWrapper>

                {errorMsgPopup && (
                  <ComponentWrapper noPaddingVertical>
                    <SignSubText>
                      <p>
                        On clicking continue, a new tab should open and direct
                        you to Bitfinex Pay. If the new tab doesn't open
                        automatically on your browser, we recommend using
                        Chrome.
                      </p>
                    </SignSubText>
                  </ComponentWrapper>
                )}

                {isLoadingTransaction ? (
                  <ComponentWrapper borderBottom>
                    <CryptoAddressWrapper
                      refresh={
                        'Just a second, we are processing your investment'
                      }
                    >
                      <CryptoAddress
                        title="your address"
                        address={''}
                        data={{
                          value: '',
                        }}
                      />
                    </CryptoAddressWrapper>
                  </ComponentWrapper>
                ) : (
                  <ComponentWrapper center>
                    <Button
                      minWidth="240px"
                      disabled={!shareData}
                      onClick={onSubmit}
                      id="bitfinex-modal-continue"
                    >
                      Continue
                    </Button>
                  </ComponentWrapper>
                )}
              </>
            ) : (
              ''
            )}
          </ComponentScroll>
        </>
      ) : (
        <ComponentWrapper center>
          <img src={gif} alt="loader" width={200} height={200} />
        </ComponentWrapper>
      )}
    </ModalWrapper>
  )
}
// Render a new image everytime, avoiding jump on the spinning
const gif = `https://res.cloudinary.com/stokr/image/upload/f_auto,q_auto/v1575035788/Static/stokr_loader_white_400x400_egnhgb.gif?a=${Math.random()}`

SignSubConnectBitfinex.propTypes = {
  changeStep: PropTypes.func.isRequired,
}

export default SignSubConnectBitfinex
