import React from 'react'
import styled from 'styled-components'

const Icon = styled.svg``

export const Share = props => (
  <Icon
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g id="STO-Pages" fill="none" fillRule="evenodd" strokeLinecap="round">
      <g
        id="2.-Desktop-Pitch-Tab"
        transform="translate(-271 -5126)"
        strokeWidth="1.5"
        stroke="currentColor"
      >
        <g id="Wrap-Up" transform="translate(0 5040)">
          <g id="Share" transform="translate(183 75)">
            <g id="Share-Icon" transform="translate(75)">
              <g id="Group-11" transform="translate(13 11)">
                <path
                  d="M14.083,11.5833 C12.61,11.5833 11.417,10.3893 11.417,8.9163 C11.417,7.4433 12.61,6.2503 14.083,6.2503 C15.556,6.2503 16.75,7.4433 16.75,8.9163 C16.75,10.3893 15.556,11.5833 14.083,11.5833 Z"
                  id="Stroke-1"
                />
                <path
                  d="M3.417,6.0833 C1.944,6.0833 0.75,4.8893 0.75,3.4163 C0.75,1.9433 1.944,0.7503 3.417,0.7503 C4.89,0.7503 6.083,1.9433 6.083,3.4163 C6.083,4.8893 4.89,6.0833 3.417,6.0833 Z"
                  id="Stroke-3"
                />
                <path
                  d="M3.417,17.0833 C1.944,17.0833 0.75,15.8893 0.75,14.4163 C0.75,12.9433 1.944,11.7503 3.417,11.7503 C4.89,11.7503 6.083,12.9433 6.083,14.4163 C6.083,15.8893 4.89,17.0833 3.417,17.0833 Z"
                  id="Stroke-5"
                />
                <path d="M5.8545,4.636 L11.7685,7.593" id="Stroke-7" />
                <path d="M8.917,11.9163 L11.737,10.5743" id="Stroke-9" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </Icon>
)

export default Share
