import styled, { css } from 'styled-components/macro'

const sizes = {
  Small: '320px',
  Medium: '768px',
  Large: '1024px',
  XLarge: '1440px',
  XXLarge: '2560px',
}

const rwd = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (...args) => css`
    @media (min-width: ${sizes[label]}) {
      ${css(...args)}
    }
  `

  return acc
}, {})

export const getMedia = breakpoint => `(min-width: ${sizes[breakpoint]})`

export const DisplayOnBreakpoint = styled.div`

  ${props =>
    props.fullscreen &&
    css`
      height: 100%;
    `}

  ${props =>
    props.down &&
    css`
      display: block;

      ${rwd[props.breakpoint]`
      display: none;
    `}
    `}

  ${props =>
    props.up &&
    css`
      display: none;

      ${rwd[props.breakpoint]`
      display: block;
    `}
    `}
`

export default rwd
