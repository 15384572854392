import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import Input from 'components/Input/Input'
import {
  Wrapper,
  Container,
  EqualSign,
  Title,
  Caption,
  InputWrap,
  StyledFormError,
} from './InvestCalculator.styles'
import fixDecimals from 'utils/fix-decimals'
import { UserContext } from 'context/User/UserContext'
import { ProjectContext } from 'context/Project/ProjectContext'
import { OrderContext } from 'context/Order/OrderContext'
import { ProjectTypes } from 'constants/enums'
import { isUSInvestor } from 'utils/isUSInvestor'
import { Tooltip } from 'react-tippy'
import { TooltipIcon } from '@stokr/components-library'

const getNumericPart = (input, maxDecimals = null) => {
  const regExp = new RegExp(
    '[1-9]\\d*' +
      (maxDecimals > 0
        ? `(\\.\\d{0,${maxDecimals}})?|\\.\\d{0,${maxDecimals}}`
        : maxDecimals === 0
        ? ''
        : '(\\.\\d*)?|\\.\\d*'),
  )
  // Normalize decimal separator and
  // find numeric part (w/o leading zeros)
  const match = input.replace(/,/, '.').match(regExp)
  // Prepend leading zero if necessary
  return match ? match[0].replace(/^\./, '0.') : '0'
}

const InvestCalculator = ({ id, onChange, onBlur, onErrors }) => {
  let { user } = useContext(UserContext)
  let { project } = useContext(ProjectContext)
  let { order = {} } = useContext(OrderContext)

  const [tokens, setTokens] = useState(0)
  const [currencyValue, setCurrencyValue] = useState(0)
  const [errors, setErrors] = useState({})
  const [professionalFee, setprofessionalFee] = useState()
  const [USAccreditationFee, setUSAccreditationFee] = useState()

  useEffect(() => {
    if (user.isBMN1 && user.selectedAddress?.currencyBalanceBMN1) {
      let e = {
        target: {
          value: user.selectedAddress.currencyBalanceBMN1.toString(),
        },
      }
      //call function to set all values and handle before/after button
      changeCurrencyValue(e)
    }
  }, [user.isBMN1])

  const selectedCurrency = user.currencyInternalReference
  const userSelectedCurrency = user.currencyName
  const available = order.tokenAvailable

  const tokenName = project.tokenSymbol

  const handleBlur = (e, field) => {
    onBlur && onBlur(e, field)
  }

  const handleChange = (e, field) => {
    onChange && onChange(e, field)
  }

  const validate = ({ tokenInput, currencyValueInput, purchaseMinimum }) => {
    let error = {}

    if (tokenInput < purchaseMinimum / order.tokenPrice) {
      error = {
        tokens: `Minimum investment: ${fixDecimals(
          purchaseMinimum / order.tokenPrice,
          project.tokenRoundingDecimals,
          Math.ceil,
        )}`,
      }
    } else if (project.type !== ProjectTypes.FUND && tokenInput > available) {
      error = {
        tokens: `Unfortunately, there are not enough available ${
          project.name === 'techforgood' ? 'tokens' : 'securities'
        } for the number you entered. Please choose a smaller amount`,
      }
    } else if (
      user.selectedAddress.currencyBalanceBMN1 < currencyValueInput &&
      user.isBMN1
    ) {
      error = {
        currencyValue: `Unfortunately there are not sufficient ${userSelectedCurrency} available on your chosen AMP wallet`,
      }
    }
    console.log('error: ', error)

    onErrors(error)
    setErrors(error)
  }

  const changeTokens = (e, field) => {
    let tokensInput = getNumericPart(
      e.target.value,
      project.tokenRoundingDecimals,
    )
    let tokens = tokensInput
    var currencyValue =
      (tokens * order.tokenPrice) / user.selectedCurrencyinEURorUSD

    if (user.secondaryCurrency) {
      currencyValue = tokens * order.tokenPrice
    }

    let purchaseMinimum = order.purchaseMinimum
    var currencyValueWithoutFees,
      fee = null

    //proffessional investor fee
    if (
      user.isProfessionalInvestor &&
      order.professionalFee &&
      order.purchaseMinimumProfessional &&
      tokens < order.purchaseMinimum / order.tokenPrice
    ) {
      // Convert the fee percentage into a multiplier by dividing by 100 and adding 1
      let feeMultiplier = 1 + order.professionalFee / 100
      currencyValueWithoutFees = currencyValue
      currencyValue = currencyValue * feeMultiplier
      purchaseMinimum = order.purchaseMinimumProfessional

      const currencyValueInEURorUSD =
        currencyValue * user.selectedCurrencyinEURorUSD
      fee =
        currencyValueInEURorUSD -
        currencyValueWithoutFees * user.selectedCurrencyinEURorUSD

      setprofessionalFee(order.professionalFee)
    } else if (
      tokens >= order.purchaseMinimum / order.tokenPrice &&
      professionalFee
    ) {
      setprofessionalFee()
    }

    if (
      project.name === 'blockstream-mining2' &&
      !user.isProfessionalInvestor &&
      user.totalTokenBalance > 0
    ) {
      purchaseMinimum = order.purchaseMinimum - user.totalTokenBalanceinEURorUSD
    }

    //for US investor, fixed fee is applied to every investment
    if (isUSInvestor(user) && order.USInvestorFee && user.isUSAccredited) {
      let feeNumber = Number(order.USInvestorFee)
      currencyValueWithoutFees = currencyValue

      //for lbtc and bitcoin, we need to convert fee into btc value before adding the fee
      if (
        user.currencyInternalReference === 'lbtc' ||
        user.currencyInternalReference === 'bitcoin'
      ) {
        currencyValue =
          currencyValue + feeNumber / user.selectedCurrencyinEURorUSD
      } else {
        currencyValue = currencyValue + feeNumber
      }
      fee = feeNumber
      setUSAccreditationFee(feeNumber)
    } else setUSAccreditationFee()

    validate({
      tokenInput: tokens,
      currencyValueInput: currencyValue,
      purchaseMinimum,
    })

    let currencyValueRounded = currencyValue.toFixed(user.currencyDecimals)

    setTokens(tokensInput)
    setCurrencyValue(currencyValueRounded)

    handleChange(
      {
        target: {
          name: 'tokens',
          value: tokensInput,
          currencyValue: currencyValueWithoutFees
            ? currencyValueWithoutFees.toFixed(user.currencyDecimals)
            : currencyValueRounded,
          currencyName: selectedCurrency,
          selectedCurrencyValue: currencyValueRounded,
          feePercentage:
            !user.isUSAccredited && currencyValueWithoutFees
              ? order.professionalFee
              : null,
          feeInEURorUSD: currencyValueWithoutFees
            ? fee.toFixed(user.currencyDecimals)
            : null,
        },
      },
      field,
    )
  }

  const changeCurrencyValue = (e, field) => {
    let currencyValueInput = getNumericPart(
      e.target.value,
      user.currencyDecimals,
    )
    let currencyValue = currencyValueInput
    let tokens =
      (currencyValue / order.tokenPrice) * user.selectedCurrencyinEURorUSD

    const selectedCurrencyValue =
      (tokens * order.tokenPrice) / user.selectedCurrencyinEURorUSD

    let purchaseMinimum = order.purchaseMinimum
    var tokensValueWithoutFees,
      fee = null

    if (
      user.isProfessionalInvestor &&
      order.professionalFee &&
      order.purchaseMinimumProfessional &&
      tokens < order.purchaseMinimum / order.tokenPrice &&
      tokens > 0
    ) {
      let feeAmount = tokens * (order.professionalFee / 100)

      tokensValueWithoutFees = tokens

      tokens -= feeAmount

      purchaseMinimum = order.purchaseMinimumProfessional

      fee = (tokensValueWithoutFees - tokens) * order.tokenPrice

      setprofessionalFee(order.professionalFee)
    } else if (
      tokens >= order.purchaseMinimum / order.tokenPrice &&
      professionalFee
    ) {
      setprofessionalFee()
    }

    //allow smaller amounts for BMN2 if user has already invested before
    if (
      project.name === 'blockstream-mining2' &&
      !user.isProfessionalInvestor &&
      user.totalTokenBalance > 0
    ) {
      purchaseMinimum = order.purchaseMinimum - user.totalTokenBalanceinEURorUSD
    }

    //for US investor, fixed fee is applied to every investment
    if (isUSInvestor(user) && order.USInvestorFee && user.isUSAccredited) {
      let feeNumber = Number(order.USInvestorFee)
      let feeAmount = feeNumber / user.selectedCurrencyinEURorUSD
      tokensValueWithoutFees = tokens
      tokens -= feeAmount
      fee = feeNumber
      setUSAccreditationFee(feeNumber)
    } else {
      setUSAccreditationFee()
    }
    validate({
      tokenInput: tokens,
      currencyValueInput: currencyValue,
      purchaseMinimum,
    })

    let tokenRounded = fixDecimals(
      tokens,
      project.tokenRoundingDecimals,
      Math.floor,
    )

    setTokens(tokenRounded)
    setCurrencyValue(currencyValueInput)

    handleChange(
      {
        target: {
          name: 'tokens',
          value: tokenRounded,
          currencyValue: currencyValueInput,
          currencyName: selectedCurrency,
          selectedCurrencyValue: fixDecimals(
            selectedCurrencyValue,
            user.currencyDecimals,
          ),
          feePercentage:
            !user.isUSAccredited && tokensValueWithoutFees
              ? order.professionalFee
              : null,
          feeInEURorUSD: tokensValueWithoutFees ? fee.toFixed(2) : null,
          isBMN1: user.isBMN1,
        },
      },
      field,
    )
  }

  return (
    <Wrapper>
      {user.isBMN1 ? (
        <CurrencyContainer
          key="currency"
          errors={errors}
          user={user}
          id={id}
          currencyValue={currencyValue}
          changeCurrencyValue={changeCurrencyValue}
          handleBlur={handleBlur}
          //professionalFee={professionalFee}
        />
      ) : (
        <TokenContainer
          key="tokens"
          errors={errors}
          tokenName={tokenName}
          tokens={tokens}
          id={id}
          changeTokens={changeTokens}
          handleBlur={handleBlur}
          project={project}
          available={available}
          fixDecimals={fixDecimals}
        />
      )}
      <EqualSign>=</EqualSign>
      {user.isBMN1 ? (
        <TokenContainer
          key="tokens"
          errors={errors}
          tokenName={tokenName}
          tokens={tokens}
          id={id}
          changeTokens={changeTokens}
          handleBlur={handleBlur}
          project={project}
          available={available}
          fixDecimals={fixDecimals}
          professionalFee={professionalFee}
          usAccreditationFee={USAccreditationFee}
          disabledInput={true}
        />
      ) : (
        <CurrencyContainer
          key="currency"
          errors={errors}
          user={user}
          id={id}
          currencyValue={
            user.secondaryCurrency
              ? fixDecimals(
                  currencyValue / user.secondaryCurrencyinEURorUSD,
                  user.currencyDecimals,
                )
              : currencyValue
          }
          changeCurrencyValue={changeCurrencyValue}
          handleBlur={handleBlur}
          professionalFee={professionalFee}
          usAccreditationFee={USAccreditationFee}
          disabledInput={true}
        />
      )}
    </Wrapper>
  )
}

const TokenContainer = ({
  errors,
  tokenName,
  tokens,
  id,
  changeTokens,
  handleBlur,
  project,
  available,
  fixDecimals,
  disabledInput,
  professionalFee,
  usAccreditationFee,
}) => (
  <Container>
    <Title error={errors && errors.tokens}>{`${tokenName} ${
      project.name === 'techforgood' ? 'tokens' : 'securities'
    } `}</Title>
    <InputWrap>
      <Input
        id={id}
        name="tokens"
        value={tokens && tokens.toString()}
        onChange={changeTokens}
        onBlur={handleBlur}
        readonly={disabledInput}
        disabled={disabledInput}
      />
    </InputWrap>
    {project.type !== 'fund' && (
      <Caption>{`Still available: ${fixDecimals(
        available,
        project.tokenRoundingDecimals,
        Math.floor,
      )} ${tokenName}`}</Caption>
    )}

    {professionalFee && (
      <Caption
        style={{ paddingBottom: 12 }}
      >{`Including professional investor qualification fees:  ${professionalFee}%`}</Caption>
    )}
    {usAccreditationFee && (
      <Caption>{`Including US accreditation fees: ${usAccreditationFee} $`}</Caption>
    )}
    <StyledFormError show={errors && errors.tokens}>
      {errors && errors.tokens}
    </StyledFormError>
  </Container>
)

const CurrencyContainer = ({
  errors,
  user,
  id,
  currencyValue,
  changeCurrencyValue,
  handleBlur,
  professionalFee,
  usAccreditationFee,
  disabledInput,
}) => (
  <Container>
    <Title error={!!errors.tokens || !!errors.currencyValue}>
      {`${user.secondaryCurrency === 'LBTC' ? 'LBTC' : user.currencySymbol}  ${
        user.isBFXPay ? '(estimated)' : ''
      }`}
      {user.isBFXPay && currencyValue ? (
        <Tooltip
          position="top"
          title={`This is an estimated price. The final price may be different on Bitfinex Pay depending on the crypto/fiat exchange rate. `}
          theme="light"
          arrow
          duration={200}
        >
          <TooltipIcon onClick={(e) => e.preventDefault()} />
        </Tooltip>
      ) : null}
    </Title>
    <InputWrap>
      <Input
        id={`${id}-valueInput`}
        name={`${id}-valueInput`}
        value={currencyValue && currencyValue.toString()}
        onChange={changeCurrencyValue}
        onBlur={handleBlur}
        readonly={disabledInput}
        disabled={disabledInput}
      />
    </InputWrap>
    {professionalFee && (
      <Caption>{`Including professional investor qualification fees:  ${professionalFee}%`}</Caption>
    )}
    {usAccreditationFee && (
      <Caption>{`Including US accreditation fees: $ ${usAccreditationFee}`}</Caption>
    )}
    <StyledFormError paddingTop show={errors && errors.currencyValue}>
      {errors && errors.currencyValue}
    </StyledFormError>
  </Container>
)

InvestCalculator.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  onErrors: PropTypes.func,
}

InvestCalculator.defaultProps = {
  onChange: () => {},
  onBlur: () => {},
  onFocus: () => {},
  onErrors: () => {},
}

export default InvestCalculator
